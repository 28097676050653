@use "styles" as *;

.navigationLoader {
  background-color: rgba($white, 0.95);
  height: 100vh;
}

.navigationIco {
  height: 72px;
  width: 72px;
  @include border-radius(50%);
  position: absolute;
  top: 50%;
  left: 50%;
  @include transform(translate(-50%, -50%));
  @include box-shadow(0 0 10px rgba($black, 0.15));
  @include flex(center, center, row);
}

.capsuleIcon {
  @include flex(center, flex-start, row);
  animation: rotating 1.2s linear infinite;
  zoom: 0.8;
  .item {
    height: 20px;
    width: 20px;
    &.left {
      background: $pharma7;
      @include border-radius(50% 0 0 50%);
    }
    &.right {
      background: #fdb931;
      @include border-radius(0 50% 50% 0);
    }
  }
}
@keyframes rotating {
  0% {
    @include transform(rotate(0deg));
  }
  80% {
    @include transform(rotate(345deg));
  }
  100% {
    @include transform(rotate(360deg));
  }
}
