@use "styles" as *;

.skeletonRoot {
  width: 100%;
  min-height: 100px;
  border-radius: 10px;
  animation: fade 1.5s ease-in-out 0.5s infinite;
  background: $grey2;
}
@keyframes fade {
  0% {
    opacity: 0.8;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.8;
  }
}
