@use "../../../../../../styles" as *;

.homepageContainer {
  .searchRoot {
    @include for-size(desktop) {
      height: 180px;
      overflow: hidden;
      width: 100%;
      background: #162c2a;
      .insideSearchContainer {
        padding: 24px 0;
        margin: 0 auto;
        max-width: 628px;
        text-align: center;
        .title {
          font-size: 30px;
          margin-bottom: 24px;
          line-height: 36px;
          font-weight: 700;
          color: $white;
          .userName {
            color: $pharma6;
            display: inline-block;
            position: relative;
            margin-right: 20px;
            padding-right: 30px;
            &::before {
              content: "\ea9a";
              color: $pharma6;
              font-family: icomoon;
              position: absolute;
              right: -10px;
              top: 50%;
              transform: translateY(-50%);
            }
            &:after {
              content: "";
              width: calc(100% - 30px);
              height: 2px;
              position: absolute;
              left: 0;
              bottom: 0;
              background: $pharma6;
            }
          }
        }
      }

      .searchSelectMain {
        > div {
          height: 56px;
          > span {
            height: 24px;
            width: 24px;
            font-size: 24px;
            &::before {
              color: $pharma6;
            }
          }
          > input {
            &::placeholder {
              color: $grey9;
              font-size: 16px;
              font-weight: 400;
              opacity: 0.7;
            }
          }
        }
      }

      .verticalSepration {
        width: 1px;
        background: #d8f2e6;
        height: 100%;
        display: inline-block;
        margin-left: 50%;
      }
      .uploadPresBx {
        button {
          justify-content: center;
        }
      }
    }
    .insideSearch {
      @include for-size(desktop) {
        max-width: 1340px;
        height: 100%;
        margin: 0 auto;
        background-image: url(https://images.apollo247.in/images/ui_revamp_searchBanner1.svg),
          url(https://images.apollo247.in/images/ui_revamp_searchBanner2.svg);
        background-position: 0 100%, 100% 100%;
        background-repeat: no-repeat;
      }
      @include for-size(tablet) {
        background-position: -20% 100%, 120% 100%;
        background-size: 250px;
      }
    }
  }
}

.dynamicMainContent {
  padding-top: 32px;
  position: relative;
  z-index: 2;
  min-height: 100vh;
  [class^="GridSection_"] {
    + [class^="GridSection_"] {
      margin-top: -20px;
    }
  }
  &.showAfterSomeTime {
    opacity: 1;
    transition: opacity 0.1s ease-in-out 0.4s, height 0.1s ease-in-out 0.4s;
    background: $white;
    @include for-size(desktop) {
      min-height: auto;
    }
    @include for-size(phone) {
      min-height: 1000px;
    }
  }
  &.defaultHide {
    opacity: 0;
  }
}
.loader {
  position: absolute;
  top: 0;
  width: calc(100% - 32px);
  left: 16px;
  z-index: -1;
  background: $white;
  height: 100vh;
  > div {
    height: 500px;
  }
  &.removeHeight {
    height: initial;
    opacity: 0;
  }
}

.mainContent {
  background: $white;
  @include for-size(phone) {
    background: $lightGrey;
  }
}

.medicineMainSection {
  background: $white;
  padding: 20px 30px;
  position: relative;
  box-shadow: none;
  @include flex(flex-start, unset, row);
  align-items: flex-start;
  @include for-size(phone) {
    flex-direction: column;
    padding: 0;
    min-height: 224px;
  }
}

.medicineMainContent {
  width: 73%;
  padding: 0;
  margin-right: 15px;
  position: relative;
  @include for-size(phone) {
    width: 100%;
    padding: 0;
  }
}

.orderContent {
  width: 27%;
  @include for-size(phone) {
    width: 100%;
  }
}
.mmContent {
  padding: 0 30px;
  @include for-size(phone) {
    padding: 0 16px 20px;
    min-height: 2131px;
  }
}

.circleSliderContainer {
  height: 240px;
  padding: 20px 0;
  @include for-size(phone) {
    height: 185px;
  }
}

.specialOfferActionBtn {
  margin: 0 0 6px 0;
  > a {
    padding: 0 15px;
    @include flex(center, unset, row);
    cursor: pointer;
    height: 53px;
    & > span {
      display: inline-block;
      margin-right: 10px;
      font-size: 32px;
      padding: 0;
    }
    > p {
      margin: 0;
      font-size: 14px;
      @include font-weight(400);
      text-transform: capitalize;
    }
  }
  @include for-size(phone) {
    display: none;
  }
}

.xsImage {
  max-width: 400px;
  margin: 0 auto;
  height: auto;
}

.pr {
  position: relative;
  z-index: 2;
}

.bannerPlaceholder {
  height: 180px;
  margin: 6px 0;
  border-radius: 10px !important;
  @include for-size(phone) {
    height: 140px;
  }
}
.modalBody {
  max-height: calc(100vh - 200px);
  overflow-y: auto;
  margin-bottom: 10px;
  padding-bottom: 50px;
}
.icon-ic_accordion_down:before {
  content: "\e949";
  color: #02475b;
}
.icon-ic_arrow_right:before {
  content: "\ea92";
  color: #02475b;
}

.icon-heartfilled:before {
  content: "\eb7d";
  color: #890000;
}

.icon-notificationOff:before {
  content: "\eb7e";
  color: #02475b;
}

.grid,
.banner {
  height: 110px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 44px;
  border-bottom: 1px solid black;
}
.cartIcon {
  font-size: 36px !important;
}

.shimmerWrap {
  @include for-size(phone) {
    > div {
      margin-bottom: 32px;
    }
  }
  @include for-size(desktop) {
    > div {
      margin-bottom: 56px;
    }
  }
}
.stickyContainer {
  position: fixed;
  right: 16px;
  bottom: 150px;
  @include for-size(phone) {
    bottom: 180px;
  }

  z-index: 8;
  width: 56px;
}
.whatsUpBanner {
  @include for-size(desktop) {
    min-height: 157px;
  }
  @include for-size(desktop) {
    min-height: 494px;
  }
}
