@use "styles" as *;

.whatsUpBannerMain {
  background: linear-gradient(
    92.82deg,
    #fff6de 2.08%,
    #ffddd6 74.9%,
    #fff4dc 97.17%
  );
  border-radius: 5px;
  min-height: 157px;
  padding: 0 30px;
  @include flex(center, space-between, row);
  overflow: hidden;
  position: relative;
}

.logoSection {
  @include flex(center, space-between, row);
  .logoBx {
    width: 90px;
    height: 64px;
    margin-right: 15px;
    img {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }
  .orderTxt {
    width: 203px;
    font-size: 18px;
    padding-right: 14px;
    line-height: 1.3;
    span {
      display: inline-block;
    }
    .whatsUpTxt {
      display: block;
      color: $secondaryColor;
      @include font-weight(700);
      font-size: 30px;
      text-transform: uppercase;
    }
    b {
      @include font-weight(700);
      margin-right: 10px;
    }
    .bottomTxt {
      color: $primaryColor;
      text-transform: uppercase;
      font-size: 30px;
      line-height: 1;
    }
  }
}

.getCashBackBx {
  text-align: center;
  max-width: 352px;
  @include flex(center, center, column);
  margin-right: 42px;
  .cashBacktxt {
    font-size: 16px;
    line-height: 25px;
    margin-bottom: 8px;
    > .highlightTxt {
      @include font-weight(700);
      text-transform: uppercase;
      display: inline-block;
    }
  }
  > .actionBtn {
    background: $secondaryColor;
    backdrop-filter: blur(40px);
    border-radius: 4px;
    color: $white;
    min-height: 40px;
    @include flex(center, center, row);
    font-size: 16px;
    line-height: 14px;
    padding: 0 16px;
    border: none;
    cursor: pointer;
    white-space: nowrap;
    > img {
      max-height: 22px;
      max-width: 22px;
      width: 100%;
      margin-left: 8px;
    }
  }
}

.rightSection {
  width: 250px;
  text-align: center;
  > img {
    max-width: 250px;
    max-height: 144px;
    object-fit: contain;
    position: relative;
    bottom: -13px;
  }
}

@include for-size(phone) {
  .whatsUpBannerMain {
    flex-direction: column;
    position: relative;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 10px 14px 0;
    margin-bottom: 20px;
    min-height: 137px;
    position: relative;
  }
  .logoSection {
    align-items: flex-start;
    justify-content: flex-start;
    .logoBx {
      position: absolute;
      right: 10px;
      top: 5px;
      margin: 0;
      width: 60px;
      height: 37px;
    }
    .orderTxt {
      width: 100%;
      font-size: 12px;
      margin-bottom: 3px;
      line-height: 24px;
      padding-right: 50px;
      > b {
        margin-right: 3px;
      }
      .whatsUpTxt,
      .bottomTxt {
        display: inline-block;
        font-size: 14px;
      }
      .whatsUpTxt {
        padding: 0 5px;
      }
    }
  }
  .getCashBackBx {
    text-align: left;
    align-items: flex-start;
    position: relative;
    z-index: 0;
    margin: 0 0 20px 0;
    max-width: auto;
    .cashBacktxt {
      font-size: 9px;
      line-height: 14px;
      margin-bottom: 29px;
      max-width: 55%;
    }
    > .actionBtn {
      max-width: 226px;
      font-size: 12px;
      padding: 0 11px;
    }
  }
  .rightSection {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 1;
    width: initial;
    > img {
      max-height: 100px;
      position: relative;
      bottom: -4px;
    }
  }
}
