@use "styles" as *;

.grid {
  display: grid !important;
  grid-gap: 6px;
  grid-auto-flow: row;
  width: 100%;
  .Item {
    background: $white;
    min-height: 30px;
    justify-self: center;
    width: 100%;
  }
}
.cardsShimmer {
  @include flex(flex-start, space-between, row);
  gap: 16px;
  overflow: hidden;
  width: 100%;
  flex-wrap: wrap;
  > div,
  > span {
    width: calc(25% - 16px) !important;
  }
}
