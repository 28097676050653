@use "styles" as *;

.faqContainer {
  margin-top: 40px;
  @media only screen and (max-width: 768px) {
    padding: 0 0 40px 0;
  }
}
.faqContent {
  width: 100%;
  background: $white;
  border-radius: 5px;
  font-size: 14px;
  line-height: 23px;
  > h2 {
    margin: 0 0 15px;
    font-size: 24px;
    line-height: 28px;
    font-weight: 700;
    @include for-size(phone) {
      font-size: 16px;
      line-height: 19px;
    }
  }
  p {
    color: $grey8;
  }
  @media only screen and (max-width: 768px) {
    margin-top: 0;
    box-shadow: none;
    //padding: 20px 16px;
    min-height: 200px;
  }
}

.expandIcon {
  width: 10px;
  height: 10px;
  margin: 5px 0;
  border: 2px solid transparent;
  border-right-color: $primaryColor;
  border-bottom-color: $primaryColor;
  transform: rotate(45deg);
  transition: 0.2s ease;
  position: relative;
  border-radius: 2px;
  &.active {
    transform: rotate(225deg);
  }
}

.accordion {
  border-bottom: 1px solid rgba($primaryColor, 0.3);
  &:last-child {
    border: none;
  }
  .accordionHeader {
    padding: 12px 0;
    @include flex(flex-start, flex-start, row);
    cursor: pointer;
    min-height: 56px;
    h2 {
      font-size: 14px;
      line-height: 20px;
      margin: 0;
      font-weight: 600;
      @media only screen and (max-width: 768px) {
        width: 90%;
      }
    }
  }
  .accordionBody {
    padding: 0 0 20px;
    .faqAns {
      font-size: 12px;
      line-height: 20px;
    }
  }
}
.displayNone {
  display: none;
}
