@use "styles" as *;

.containerWrap {
  width: 100%;
  @include flex(flex-start, space-between, row);

  &.hasProducts {
    .leftSection {
      width: 70%;

      @include for-size(phone) {
        width: 100%;
        order: 2;
      }
    }

    .rightSection {
      width: 30%;

      @include for-size(phone) {
        width: 100%;
        order: 1;
      }
    }
  }

  &.noProducts {
    .leftSection {
      width: 100%;

      @include for-size(phone) {
        width: 100%;
      }
    }
  }

  @include for-size(phone) {
    @include flex(unset, unset, column);
  }
}

.seoContentWrap {
  border-top: 1px solid $grey3;
  margin-top: 40px;
  padding: 20px 0px;
  position: relative;
  background: $white;
  @media only screen and (max-width: 767px) {
    padding: 0 0 40px 0;
    border-top: 1px dashed #02475b;
    border-bottom: 1px dashed #02475b;
  }
  .seoContent {
    p,
    ul li {
      font-size: 12px;
      line-height: 16px;
      color: $grey8;
    }
    ul {
      padding: 20px;
      > li {
        list-style: disc;
        padding-left: 10px;
      }
    }
    @media only screen and (max-width: 767px) {
      //max-height: 509px;
      //overflow: hidden;
    }
    &.open {
      @media only screen and (max-width: 767px) {
        max-height: none;
      }
    }
  }
  .expandBtn {
    font-size: 13px;
    line-height: 17px;
    color: #fc9916;
    @include font-weight(700);
    text-transform: uppercase;
    border: none;
    outline: none;
    background: transparent;
    position: absolute;
    right: 20px;
    bottom: 12px;
    display: none;
    @media only screen and (max-width: 767px) {
      display: inline-block;
    }
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 14px;
    line-height: 20px;
    margin: 32px 0 16px;
    font-weight: 600;
  }
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.5;
  }
  & a {
    @include font-weight(600);
  }
}

.productPricetable {
  border-top: 1px solid $grey3;
  margin: 40px 0 0 12px;
  padding: 20px 0;
  position: relative;
  background: $white;
  display: flex;
  flex-direction: column;

  h2 {
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    padding: 12px 8px;

    @include for-size(phone) {
      padding: 12px 0;
    }
  }

  @include for-size(phone) {
    margin-left: 0;
    border-top: 1px dashed #02475b;
  }

  .ppRowHeader,
  .ppRow {
    display: flex;
    border-bottom: 1px solid $grey3;
    padding: 12px 8px;
    font-size: 14px;
    line-height: 20px;
    text-align: left;

    .ppcolumn {
      flex: 1;
      display: flex;
      padding-right: 14px;
    }

    .ppcolumn:first-child {
      flex: 3;
    }

    @include for-size(phone) {
      padding: 12px 0;
    }
  }

  .ppRow {
    font-weight: 400;

    .ppcolumn {
      span {
        padding-right: 3px;
      }
      color: $grey8;
    }

    .ppcolumn:first-child {
      color: $blue7;
    }
  }

  .ppRowHeader {
    font-weight: 700;
  }
}
