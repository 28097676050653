@use "styles" as *;

.gridMainSection {
  margin-bottom: 56px;
  @include for-size(phone) {
    margin-bottom: 32px;
  }
}
.gridGapZero {
  > div {
    gap: 0 !important;
  }
}
.headingTextSkeleton {
  width: 100%;
  @include for-size(desktop) {
    height: 56px;
    .headTextSkeleton {
      height: 29px;
    }
  }
  @include for-size(phone) {
    height: 35px;
    .headTextSkeleton {
      height: 19px;
    }
  }
}
