@use "styles" as *;

.bannerMainSection {
  margin-bottom: 56px;
  @include for-size(phone) {
    margin-bottom: 32px;
  }
}
.bannerSectionDefault {
  margin-bottom: 32px;
}
.accordionPdpList {
  ul {
    padding-left: 20px;
    list-style: unset;
    margin: unset;
  }
}
